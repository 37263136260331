
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { BagEstimationClient, TransportTypeConfigurationClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class EditProcessTransportModal extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() prevProcessName: string;
    @Prop() productionPhase: string;

    @Prop({
        default: () => new OM.TransportProductionPhase()
    }) editModel: OM.TransportProductionPhase;

    @Prop() transportDistanceCalculation: OM.TransportDistanceCalculationVM[];
    @Prop() countries: OM.TextIdentifier[];
    @Prop() saveCallback: (updatedModel: OM.TransportProductionPhase) => void;

    @Prop({
        default: false
    }) originCountryReadonly: boolean;

    differentNextProductionSite: boolean = false;
    isTransportInternational: boolean = false;
    transportTypes: OM.TransportTypeConfiguration[] = [];
    from: string = "";
    to: string = "";
    editKmDenied: boolean = false;
    modelTransportType: OM.TransportTypeDistance = new OM.TransportTypeDistance();
    model: OM.EditLayerTransportationVM = new OM.EditLayerTransportationVM();

    loaded: boolean = false;

    created() {
        this.model.identifier = this.estimationIdentifier;
        this.model.productionPhase = this.productionPhase;

        var proms = [];
        proms.push(TransportTypeConfigurationClient.getAllTransportTypes());
        proms.push(BagEstimationClient.getCountryOfOrigin(this.estimationIdentifier, this.prevProcessName));

        Promise.all(proms)
        .then(xs => {
            this.transportTypes = xs[0];

            if(this.editModel.transportCO2) {
                this.model.fabricComposition.rawMaterial.identifier = this.editModel.rawMaterial.identifier;
                this.modelTransportType.from = this.countries.find(x => x.text == this.editModel.countryOfOrigin).identifier;
                this.modelTransportType.to = this.countries.find(x => x.text == this.editModel.countryOfDestination).identifier;

                if(this.modelTransportType.from != this.modelTransportType.to)
                    this.differentNextProductionSite = true;

                this.model.transportTypes = this.editModel.transportTypes;
            }
            else
                this.modelTransportType.from = xs[1];
                
            this.loaded = true;
        })

    }

    get canAdd(){
        return this.modelTransportType.transportType && this.modelTransportType.distance && this.model.countryOfOrigin && this.model.countryOfDestination;
    }

    get filteredTransportTypesFromIsInternational() {
        var filteredList = this.transportTypes.filter(x => x.isInternational == this.isTransportInternational);
        return filteredList.map(x => x.transportType);
    }

    calcKmFromConfig(transportType: string) {
        this.from = this.countries.find(x => x.identifier == this.model.countryOfOrigin).text;
        this.to = this.countries.find(x => x.identifier == this.model.countryOfDestination).text;
        
        var existingConfiguration = this.transportDistanceCalculation.find(x => x.from.toLocaleLowerCase() == this.from.toLocaleLowerCase() &&
            x.to.toLocaleLowerCase() == this.to.toLocaleLowerCase() && x.transportType == transportType);

        if(!existingConfiguration)
            return null;

        return existingConfiguration.distanceKM;
    }
    checkKmsFromConfiguration() {
        this.model.countryOfOrigin = this.modelTransportType.from;

        if(!this.differentNextProductionSite)
            this.modelTransportType.to = this.modelTransportType.from;

        this.model.countryOfDestination = this.modelTransportType.to;

        var internationalTransports = this.transportTypes.filter(x => x.isInternational == true).map(y => y.transportType);
        var existTransport = this.model.transportTypes.filter(x => internationalTransports.indexOf(x.transportType) > -1);

        if(!this.model.countryOfOrigin || !this.model.countryOfDestination || (!this.modelTransportType.transportType && existTransport.length == 0))
            return;
        
        var distance = this.calcKmFromConfig(this.modelTransportType.transportType);

        if(!distance) {
            this.modelTransportType.distance = null;
            this.editKmDenied = false;
        }
        else {
            this.modelTransportType.distance = distance;
            this.editKmDenied = true;
        }

        if(existTransport.length > 0) {
            existTransport.forEach(element => {
                var indexOf = this.model.transportTypes.indexOf(element);
                if(indexOf > -1) {
                    var distanceKm = this.calcKmFromConfig(element.transportType);
                    
                    if(distanceKm)
                        this.model.transportTypes[indexOf].distance = distanceKm;
                    else
                        this.model.transportTypes.splice(indexOf, 1);
                }
            });
        }
    }

    addTransportType() {
        this.modelTransportType.from = this.from;
        this.modelTransportType.to = this.to;
        this.model.transportTypes.push(this.modelTransportType);

        this.modelTransportType = new OM.TransportTypeDistance();
        this.modelTransportType.from = this.model.countryOfOrigin;
        this.modelTransportType.to = this.model.countryOfDestination;

        this.model.countryOfOrigin = "";
        this.model.countryOfDestination = "";
    }
    removeTransportType(index: number) {
        this.model.transportTypes.splice(index, 1);
    }

    get isDisabled() {
        if (this.differentNextProductionSite)
            return !this.modelTransportType.from || this.model.transportTypes.length == 0;
        else
            return false;
    }

    save() {
        if(!this.differentNextProductionSite)
            this.modelTransportType.to = this.modelTransportType.from;

        this.model.countryOfOrigin = this.countries.find(x => x.identifier == this.modelTransportType.from).text;
        this.model.countryOfDestination = this.countries.find(x => x.identifier == this.modelTransportType.to).text;
        
        var ris = new OM.TransportProductionPhase();
        ris.countryOfOrigin = this.model.countryOfOrigin;
        ris.countryOfDestination = this.model.countryOfDestination;
        ris.productionPhase = this.productionPhase;
        ris.transportTypes = this.model.transportTypes;

        this.saveCallback(ris);
    }
}
