
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, BagEstimationClient, GarmentClient, TransportDistanceCalculationClient } from '@/services/Services';
import ProcessTransport from './components/processTransport.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ProcessTransport
    }
})
export default class ProcessesLocationsAndTransports extends Vue {

    estimationIdentifier: string = "";
    bagData: OM.BagData = new OM.BagData();
    processes: OM.BagProcess[] = [];

    electricityCountries: OM.TextIdentifier[] = [];
    transportationCountries: OM.TransportDistanceCalculationVM[] = [];
    
    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.init();
    }

    init() {
        Promise.all([
            BagEstimationClient.getEstimationBagData(this.estimationIdentifier),
            ElectricityEmissionClient.getCountries(true),
            TransportDistanceCalculationClient.getAllConfigurations(),
        ])
        .then(xs => {
            this.bagData = xs[0];
            this.electricityCountries = xs[1];
            this.transportationCountries = xs[2];

            GarmentClient.getAllProcessesByBagTypeAndProcessType(this.bagData.garment.identifier, this.bagData.bagType, this.bagData.processType)
            .then(y => {
                this.processes = y;
            })
        })
    }

    getPrevProcess(actualProcessName: string) {
        var findIndex = this.processes.findIndex(x => x.name == actualProcessName);

        if(findIndex > 0)
            return this.processes[findIndex -1].name;
        else
            return "";
    }

    get isDisabled() {
        var alreadyDoneCount = this.bagData.processesCo2eEmissionsAndTransports.length;
        return alreadyDoneCount < this.processes.length;
    }
}
