
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { BagEstimationClient } from '@/services/Services';
import EditProcessTransportModal from '../modals/editProcessTransportModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class ProcessTransport extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() prevProcessName: string;
    @Prop() processName: string;
    @Prop() processEmissionFactor: number;
    @Prop() bagData: OM.BagData;
    @Prop() electricityCountries: OM.TextIdentifier[];
    @Prop() transportationCountries: OM.TransportDistanceCalculationVM[];
    @Prop() index: number;
    @Prop() originCountryReadonly: boolean;
    
    created() {
    }

    get processTransportation() {
        return this.bagData.processesCo2eEmissionsAndTransports.filter(x => x.transportProductionPhase.productionPhase == this.processName)[0];
    }

    get checkIfDisabled() {
        if(this.index == 0)
            return false;

        return this.index > this.bagData.processesCo2eEmissionsAndTransports.length;
    }

    openTransportModal() {
        var editModel = new OM.TransportProductionPhase();
        if(this.processTransportation && this.processTransportation.transportProductionPhase && this.processTransportation.transportProductionPhase.countryOfDestination)
            editModel = this.processTransportation.transportProductionPhase;

        this.$opModal.show(EditProcessTransportModal, {
            estimationIdentifier: this.estimationIdentifier,
            prevProcessName: this.prevProcessName,
            productionPhase: this.processName,
            transportDistanceCalculation: this.transportationCountries,
            countries: this.electricityCountries,
            editModel: editModel,
            originCountryReadonly: this.originCountryReadonly,
            saveCallback: (trasportDetail : OM.TransportProductionPhase) => {
                var model = new OM.FootwearEstimationTransportVM();
                model.identifier = this.estimationIdentifier;
                model.transport = new OM.ProcessesCo2eEmissionsAndTransports();
                model.transport.processCo2eEmissionFactor = this.processEmissionFactor;
                model.transport.transportProductionPhase = trasportDetail;

                BagEstimationClient.saveTransport(model)
                .then(x => {
                    this.$opModal.closeLast();
                    this.$emit('update');
                })
            }
        })
    }
}
