import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-end mt-5" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_process_transport = _resolveComponent("process-transport")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.processes, (process, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createVNode(_component_process_transport, {
          estimationIdentifier: _ctx.estimationIdentifier,
          prevProcessName: _ctx.getPrevProcess(process.name),
          processName: process.name,
          processEmissionFactor: process.emissionFactor,
          bagData: _ctx.bagData,
          electricityCountries: _ctx.electricityCountries,
          transportationCountries: _ctx.transportationCountries,
          index: index,
          originCountryReadonly: index == _ctx.processes.length - 1,
          onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.init()))
        }, null, 8, ["estimationIdentifier", "prevProcessName", "processName", "processEmissionFactor", "bagData", "electricityCountries", "transportationCountries", "index", "originCountryReadonly"])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "btn btn-secondary me-3",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.back()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Previous",
          text: "Previous"
        })
      ]),
      _createElementVNode("button", {
        class: "btn btn-tertiary",
        disabled: _ctx.isDisabled,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/bag-calculator/packaging/' + _ctx.estimationIdentifier)))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Continue",
          text: "Continue"
        })
      ], 8, _hoisted_2)
    ])
  ], 64))
}