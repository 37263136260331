import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-436c7804"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "table table-striped mt-3 mb-5" }
const _hoisted_4 = { style: {"width":"270px"} }
const _hoisted_5 = { style: {"width":"270px"} }
const _hoisted_6 = { style: {"width":"270px"} }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { colspan: "99" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { style: {"width":"270px"} }
const _hoisted_11 = { style: {"width":"270px"} }
const _hoisted_12 = {
  key: 0,
  class: "fs-12"
}
const _hoisted_13 = { style: {"width":"270px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.processName), 1),
      _createElementVNode("button", {
        class: "btn btn-tertiary",
        disabled: _ctx.checkIfDisabled,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openTransportModal()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Manage Transport",
          text: "Manage Transport"
        })
      ], 8, _hoisted_2)
    ]),
    _createElementVNode("table", _hoisted_3, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", _hoisted_4, [
            _createVNode(_component_localized_text, {
              localizedKey: "Origin Country",
              text: "Origin Country"
            })
          ]),
          _createElementVNode("td", _hoisted_5, [
            _createVNode(_component_localized_text, {
              localizedKey: "Destination Country",
              text: "Destination Country"
            })
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Transports",
              text: "Transports"
            })
          ]),
          _createElementVNode("td", _hoisted_6, [
            _createVNode(_component_localized_text, {
              localizedKey: "Transport CO2e Grams",
              text: "Transport CO2e Grams"
            })
          ])
        ])
      ]),
      _createElementVNode("tbody", null, [
        (!_ctx.processTransportation)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
              _createElementVNode("td", _hoisted_8, [
                _createVNode(_component_localized_text, {
                  localizedKey: "No Elements Found",
                  text: "No Elements Found"
                })
              ])
            ]))
          : (_openBlock(), _createElementBlock("tr", _hoisted_9, [
              _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.processTransportation.transportProductionPhase.countryOfOrigin), 1),
              _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.processTransportation.transportProductionPhase.countryOfDestination), 1),
              _createElementVNode("td", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.processTransportation.transportProductionPhase.transportTypes, (type, j) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "fs-12 mb-1",
                    key: j
                  }, _toDisplayString(type.transportType) + " - " + _toDisplayString(_ctx.$filters.approximation(type.transportCO2)), 1))
                }), 128)),
                (_ctx.processTransportation.transportProductionPhase.transportTypes.length == 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, "---"))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.$filters.approximation(_ctx.processTransportation.transportProductionPhase.transportCO2)), 1)
            ]))
      ])
    ])
  ], 64))
}